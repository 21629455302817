import type { CustomFlowbiteTheme } from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {
    button: {
        color: {
            primary: "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
        },
        outline: {
            on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
        },
        size: {
            md: "text-sm px-3 py-2",
        },
    },
    navbar: {
        root: {
            base: "fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700",
        }

    },
    sidebar: {
        // root: {
        //     base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700",
        // }
    },
}

export default flowbiteTheme