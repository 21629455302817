import React, { useEffect, useState } from 'react';
import NavbarSidebarLayout from '../../layouts/navbar-sidebar-layout';
import { Breadcrumb, BreadcrumbItem, Button, Datepicker, Timeline, Drawer, Card, List, Alert, Avatar, Label, TextInput, Toast } from 'flowbite-react';
import { HiArrowNarrowRight, HiCheckCircle, HiExclamationCircle, HiHome, HiInformationCircle, HiPaperAirplane, HiUsers } from 'react-icons/hi';
import { API_ENDPOINTS } from '../../api';
import axios from 'axios';
import { FaPlane, FaUserAltSlash, FaBriefcase, FaHouseUser, FaLaptop, FaThumbtack, FaBriefcaseMedical, FaGraduationCap, FaPlaneDeparture, FaPlaneArrival, FaArrowCircleRight, FaClock } from "react-icons/fa";
import './crew-profile.css'
import { useAuth } from '../../AuthContext';
import bcrypt from "bcrypt";
const currentDate = new Date(); // Get current date

// Set initial date to the start of the current month
const initialStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

// Set initial date to the end of the current month
const initialEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

const CrewProfile: React.FC = () => {
    // Your component logic here
    interface CrewMember {
        firstName: string;
        lastName: string;
        crewCategory: string;
        // Add other properties as needed
    }
    const { user, logout } = useAuth();
    const initialUserDetails = user && user.usersData ? user.usersData : null
    const [userDetails, setUserDetails] = useState(initialUserDetails);

    const [crewHeaderData, setCrewHeaderData] = useState<any>({});
    const [crewAccountData, setCrewAccountData] = useState<any>({});

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [displayLoading, setDisplayLoading] = useState(false);

    const [isOldPasswordValid, setIsOldPasswordValid] = useState(true);
    const [isCoinfiormPasswordValid, setIsConfirmPasswordValid] = useState(true);




    const loadCrewProfile = () => {

        const crewId = userDetails.crewId
        console.log(crewId)

        try {
            axios
                .post(API_ENDPOINTS.GET_CP_PROFILE_DATA, {
                    crewId: crewId,

                })
                .then(function (response) {
                    console.log(response)
                    setCrewHeaderData(response.data.crewHeaderData)
                    setCrewAccountData(response.data.crewAccountData)
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        loadCrewProfile()


    }, [])

    const renderFisrtLetterOFName = (name: string) => {
        var nval = name == undefined ? "0" : name
        const LetterVal = nval.substring(0, 1)
        return LetterVal
    }

    const checkConfirmPassword = () => {
        console.log(oldPassword, newPassword, confirmPassword)
        if (newPassword != confirmPassword) {
            
            setDisplayLoading(false)
            setIsConfirmPasswordValid(false)
            return false;
        } else {
            return true
            setIsConfirmPasswordValid(true)
        }
    }
    const handlePasswordUpdate = () => {
        setDisplayLoading(true)
        setIsOldPasswordValid(true)
        setIsConfirmPasswordValid(true)
        if (checkConfirmPassword()) {
            try {
                axios.post(API_ENDPOINTS.UPDATE_CP_PASSWORD, {
                    crewId: userDetails.crewId,
                    oldPassword: oldPassword,
                    newPassword: newPassword

                }).then(function (response) {
                    console.log(response)
                    setConfirmPassword('')
                    setOldPassword('')
                    setNewPassword('')
                    setToastMessage("Password updated successfully, Please logout and login again")
                    setDisplayLoading(false)
                    setShowToast(true)
                    setTimeout(() => {
                        setShowToast(false)
                    }, 5000)
                    
                }).catch(function (error) {
                    console.log(error.response)
                    const errorCode = error.response.data.code;
                    const errorMessage = error.response.data.error;

                    console.error('Error code:', errorCode);
                    console.error('Error message:', errorMessage);

                    if (errorCode == 1001) {
                        setIsOldPasswordValid(false)
                    }

                    setDisplayLoading(false)
                })
            } catch (error) {
                console.error(error)

            }
        }

    }

    return (
        <div>
            <NavbarSidebarLayout>
                <div className=" z-50 block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                    <div className="mb-1 w-full">
                        <div className="mb-4">
                            <Breadcrumb className="mb-4">
                                <BreadcrumbItem href="#"><div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">Home</span>
                                </div>

                                </BreadcrumbItem>
                                <Breadcrumb.Item href="/myroster">
                                    My Profile
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            {/* <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Current Roster
              </h1> */}
                        </div>
                        <div>

                            <h5 className="dark:text-whitev mb-5 text-2xl font-bold tracking-tight text-gray-900">
                                Crew Profile
                            </h5>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className=''>
                                    <Card>
                                        <div className='flex flex-col flex-wrap'>
                                            <Avatar placeholderInitials={renderFisrtLetterOFName(crewHeaderData.firstName)} size="lg" rounded />

                                            <h5 className="mb-3 text-xl font-bold  dark:text-white">{crewHeaderData.firstName + ' ' + crewHeaderData.lastName}</h5>
                                            <div className='mb-1 flex gap-2'>
                                                <svg className="size-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd" d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.382l1.447.723.005.003.027.013.12.056c.108.05.272.123.486.212.429.177 1.056.416 1.834.655C7.481 13.524 9.63 14 12 14c2.372 0 4.52-.475 6.08-.956.78-.24 1.406-.478 1.835-.655a14.028 14.028 0 0 0 .606-.268l.027-.013.005-.002L22 11.381V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.447 7.894.553-.276V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.382l.553.276.002.002.004.002.013.006.041.02.151.07c.13.06.318.144.557.242.478.198 1.163.46 2.01.72C7.019 15.476 9.37 16 12 16c2.628 0 4.98-.525 6.67-1.044a22.95 22.95 0 0 0 2.01-.72 15.994 15.994 0 0 0 .707-.312l.041-.02.013-.006.004-.002.001-.001-.431-.866.432.865ZM12 10a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd" />
                                                </svg>
                                                <p className="  font-semibold text-gray-900 dark:text-white">{crewHeaderData.crewCategory} </p>
                                            </div>

                                            <div className='flex gap-2'>
                                                <svg className="size-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd" />
                                                </svg>

                                                <p className="  font-semibold text-gray-900 dark:text-white">Sri Lanka</p>
                                            </div>
                                            <div className='mt-5 flex flex-col flex-wrap gap-1'>
                                                <p className="  m-0 p-0 font-semibold text-gray-500 dark:text-white">Email address</p>
                                                <p className="  m-0 font-semibold text-gray-700 dark:text-white">{crewHeaderData.Email1}</p>
                                            </div>
                                            <div className='mt-5 flex flex-col flex-wrap gap-1'>
                                                <p className="  font-semibold text-gray-500 dark:text-white">Home address</p>
                                                <p className="  font-semibold text-gray-700 dark:text-white">{crewHeaderData.Address1}</p>
                                                <p className="  font-semibold text-gray-700 dark:text-white">{crewHeaderData.Address2}</p>
                                                <p className="  font-semibold text-gray-700 dark:text-white">{crewHeaderData.City}</p>
                                            </div>
                                            <div className='mt-5 flex flex-col flex-wrap gap-1'>
                                                <p className="  font-semibold text-gray-500 dark:text-white">Phone Number</p>
                                                <p className="  font-semibold text-gray-700 dark:text-white">{crewHeaderData.Mobile1}</p>
                                            </div>

                                        </div>
                                    </Card>
                                </div>
                                <div className='col-span-2'>
                                    <Card>

                                        <h4 className="text-2xl font-bold dark:text-white">Password Information</h4>
                                        <div className='grid grid-cols-2 gap-4'>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="password1" value="Current password" />
                                                </div>
                                                <TextInput color={isOldPasswordValid ? 'gray' : 'failure'} id="password1" type="password" required onChange={(e) => setOldPassword(e.target.value)}
                                                    helperText={isOldPasswordValid ? <> </> : <> The current password is not correct. </>

                                                    } />
                                            </div>
                                            <div>

                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="password1" value="New password" />
                                                </div>
                                                <TextInput color={isCoinfiormPasswordValid ? 'gray' : 'failure'} id="password1" type="password" required onChange={(e) => setNewPassword(e.target.value)} 
                                                    
                                                    />
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <Label htmlFor="password1" value="Confirm password" />
                                                </div>
                                                <TextInput  color={isCoinfiormPasswordValid ? 'gray' : 'failure'} id="password1" type="password" required onChange={(e) => setConfirmPassword(e.target.value)} 
                                                    helperText={isCoinfiormPasswordValid ? <> </> : <> The new password and confirmation password do not match. Please ensure both fields are identical. </>

                                                    } 
                                                    />
                                            </div>
                                        </div>
                                        <div>

                                            <p className='mt-2 font-bold text-gray-800 dark:text-white'>Password requirements:</p>
                                            <p className='mt-2 font-semibold text-gray-500 dark:text-white'>Ensure that these requirements are met:</p>

                                            <ul className="max-w-md list-inside space-y-1 text-gray-500 dark:text-gray-400">
                                                <li className="flex items-center">
                                                    {/* <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg> */}
                                                    <svg className="me-2 size-3.5 shrink-0 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg>
                                                    At least 6 characters (and up to 20 characters)
                                                </li>
                                                <li className="flex items-center">
                                                    {/* <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg> */}
                                                    <svg className="me-2 size-3.5 shrink-0 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg>
                                                    At least one lowercase character
                                                </li>
                                                <li className="flex items-center">
                                                    {/* <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg> */}
                                                    <svg className="me-2 size-3.5 shrink-0 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg>
                                                    At least one UPPERCASE character
                                                </li>
                                                <li className="flex items-center">
                                                    {/* <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg> */}
                                                    <svg className="me-2 size-3.5 shrink-0 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg>
                                                    At least one numeric character (0 to 9)
                                                </li>
                                                {/* <li className="flex items-center">
                                                    <svg className="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                    </svg>
                                                    At least one special character, e.g., ! @ # ?
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div>
                                            <Button color="success" onClick={handlePasswordUpdate}>Update Password</Button>
                                        </div>
                                    </Card>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </NavbarSidebarLayout>
            {showToast && <Toast className="toast-postion">
                <div className="inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                    <HiExclamationCircle className="size-5" />
                </div>
                <div className="ml-3 text-sm font-normal">{toastMessage}</div>
                <Toast.Toggle onDismiss={() => setShowToast(false)} />
            </Toast>}
        </div>
    );
};

export default CrewProfile;
