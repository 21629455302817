import type { FC, PropsWithChildren } from "react";
import ExampleSidebar from "../components/sidebar";
import ExampleNavbar from "../components/navbar";

const NavbarSidebarLayout:FC<PropsWithChildren>=({children})=>{
    return(
        <>
            <ExampleNavbar/>
            <div className="flex items-start pt-16">
                <ExampleSidebar /> 
                <MainContent>{children}</MainContent>
            </div>
        </>
    )
}

const MainContent :FC<PropsWithChildren> =({children})=>{
    return(
        <main className="relative h-full w-full overflow-y-auto bg-gray-50 dark:bg-gray-900">
            {children}
        </main>
    )
}
export default NavbarSidebarLayout