import { Alert, Button, Card, Checkbox, Label, Spinner, TextInput, Toast } from "flowbite-react";
import { useState, type FC } from "react";
import logo from '../../images/logo.png'
import signinImage from '../../images/signinImage.jpg'
import './sign-in.css'
import { API_ENDPOINTS } from '../../api'
import { useAuth } from '../../AuthContext'
import axios from "axios";
import { HiExclamationCircle, HiFire, HiInformationCircle, HiMail } from "react-icons/hi";

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isFialedEmail, setIsFialedEmail] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const { login } = useAuth()

    interface ApiResponse {
        // Define the structure of the response data
        // You can adjust this interface based on the actual response structure
    }

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await axios.post<ApiResponse>(API_ENDPOINTS.FORGOT_PASSWORD_EMAIL, {
                email: email
            });
            console.log('Forgot password successful:', response.data);
            setIsSuccessful(true)
        } catch (error: any) {
            console.error('Forgot password failed:', error.response.data);
            setIsFialedEmail(true)
            setTimeout(() => {
                setIsFialedEmail(false)
            }, 5300)
        }
        setIsLoading(false)
    }
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await axios.post<ApiResponse>(API_ENDPOINTS.LOGIN, {
                email,
                password
            });
            console.log('Login successful:', response.data);
            login(response.data)
            window.location.href = '/myroster'
            // Redirect user or perform other actions upon successful login
        } catch (error: any) {
            console.log(error.response.status)
            if (error.response.status == 401) {
                setToastMessage("Incorrect email or password. Please try again.")
            } else if (error.response.status == 500) {
                setToastMessage("Something went wrong. Please try again later.")
            } else {
                setToastMessage("Something went wrong. Please try again later.")
            }
            setIsLoading(false)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 5300)
            //console.error('Login failed:', error.response.data);
            // Handle login failure (display error message, etc.)
        }
    };

    return (
        <div className="bg-topography flex min-h-screen flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">

            <Card  >
                {isSuccessful ? (
                    <div color="green" className="w-full rounded-lg bg-white p-6 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md sm:p-8 md:mt-0 ">
                        <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-green-500 dark:text-white md:text-2xl  " >
                            <HiMail className="inline-block mr-1 mb-1 text-xl font-bold leading-tight tracking-tight text-green-500 dark:text-white md:text-2xl" /> Check Your Email!
                        </h1>
                        <p className="font-light text-gray-500 dark:text-gray-400 mb-3"> We’ve sent you an email with instructions to reset your password. Please check your inbox and follow the steps provided.

                        </p>
                        <div>
                        <Button className="w-full" onClick={() => window.location.href = '/'} color={'green'}> Go to Login</Button>
                    </div>
                    </div>
                ) : isLoading ?(
                    <div>
                        <Spinner aria-label="Spinner button example" color={"green"} size="lg" />
                    </div>
                ): (<div className="w-full rounded-lg bg-white p-6 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md sm:p-8 md:mt-0">
                    <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
                        Forgot your password?
                    </h1>
                    <p className="font-light text-gray-500 dark:text-gray-400 mb-3">Simply enter your email address, and we’ll send you a verification link to reset your password.

                    </p>
                    {isFialedEmail &&
                        <Alert color="failure" icon={HiInformationCircle} className="mb-3 flex flex-col">
                            <span className="font-medium">Email Not Found!</span> <div>The email address you entered is not associated with any account. Please check the email address and try again.</div>
                        </Alert>}
                    <form onSubmit={handleForgotPassword}>
                        <div className="mb-6">
                            <Label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Your email</Label>
                            <TextInput onChange={handleEmailInput} type="email" name="email" id="email" className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500" placeholder="name@company.com" required />
                        </div>

                        <Button type="submit" className="hover:bg-primary-700 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4">Reset password</Button>
                    </form>
                </div>)
                }

            </Card >
            {showToast && <Toast className="toast-postion">
                <div className="inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                    <HiExclamationCircle className="size-5" />
                </div>
                <div className="ml-3 text-sm font-normal">{toastMessage}</div>
                <Toast.Toggle onDismiss={() => setShowToast(false)} />
            </Toast>}
        </div >
    );
};

export default ForgotPassword;