// const BASE_URL = 'http://127.0.0.1:8000/api'; // Replace with your actual API base URL
// const FILE_URL = 'http://127.0.0.1:8000'; // Replace with your actual API base URL
//sumith.scd@gmail.com
//Iron821

const BASE_URL = 'https://api.fitsair.online/api' // Replace with your actual API base URL
const FILE_URL = 'https://api.fitsair.online'
// Death959
export const API_ENDPOINTS = {
  LOGIN: `${BASE_URL}/crewlogin`,
  GET_CP_ROSTER_DATA: `${BASE_URL}/get-crewportal-myroster`,
  GET_CP_ROSTER_FLIGHT_DATA: `${BASE_URL}/get-crewportal-myroster-flightcrew`,
  GET_CP_PROFILE_DATA: `${BASE_URL}/get-crewportal-profile`,
  UPDATE_CP_PASSWORD: `${BASE_URL}/update-crewportal-password`,
  FORGOT_PASSWORD_EMAIL: `${BASE_URL}/forgot-password-email`,
  UPDATE_CREW_PASSWORD_RESET: `${BASE_URL}/update-crew-password-reset`
};

