import { useContext, useEffect, useState, type FC } from "react";
import { Avatar, Button, Dropdown, Navbar, NavbarBrand } from "flowbite-react";
import logo from '../images/logo.png'
import { useAuth } from "../AuthContext";
import { FaBars } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { SidebarContext, useSidebarContext } from "../SidebarContext";
import './Navbar.css'
const ExampleNavbar: FC = () => {

    

    const { user, logout } = useAuth();
    const initialUserDetails = user && user.usersData ? user.usersData : null
    const [userDetails, setUserDetails] = useState(initialUserDetails);

    const { toggleSidebar } = useSidebarContext();

    const handleLogout = () => {
        logout();
    }

    return (
        <Navbar fluid>
            <div className="w-full p-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                    <div className="mr-1 justify-start">
                            <Button 
                            onClick={toggleSidebar}
                            className='btn-toggleSidebar tramr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline'>
                                <HiMenu />
                            </Button>
                        </div>
                        <NavbarBrand href="/">
                            <img src={logo} className="mr-3 h-6 sm:h-8" />
                            <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">Crew Portal</span>
                        </NavbarBrand>
                        
                    </div>

                    <div className="flex items-center gap-3">
                        {/* <Avatar rounded={true} status="online" color="purple-500" bordered={true} placeholderInitials="S" /> */}
                        <Dropdown
                            label={<Avatar rounded={true} status="online" color="purple-500" bordered={true} placeholderInitials={userDetails.firstName.charAt(0)} />}
                            arrowIcon={false}
                            inline
                        >
                            <Dropdown.Header>
                                {userDetails && (
                                    <>
                                        <span className="block text-sm">{userDetails.firstName} {userDetails.lastName}</span>
                                        <span className="block truncate text-sm font-medium">{userDetails.Email1}</span>
                                    </>
                                )}
                            </Dropdown.Header>
                            {/* <Dropdown.Item>Dashboard</Dropdown.Item>
                            <Dropdown.Item>Settings</Dropdown.Item>
                            <Dropdown.Item>Earnings</Dropdown.Item> */}
                            {/* <Dropdown.Divider /> */}
                            <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default ExampleNavbar