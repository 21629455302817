import React, { createContext, useContext, useReducer, useEffect, ReactNode } from 'react';
// import PropTypes from 'prop-types';

// Define the initial state interface
interface AuthState {
  isAuthenticated: boolean;
  user: any; // Define the type for user as needed
}

// Define the action types
type AuthAction = { type: 'LOGIN', payload: any } | { type: 'LOGOUT' };

// Create a reducer function to handle authentication actions
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};
const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};
// Create the AuthContext
const AuthContext = createContext<{ state: AuthState; login: (user: any) => void; logout: () => void } | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  const { state: { user }, login, logout } = context;
  return {
    isAuthenticated: !!user, // Check if user object exists
    user: user,
    login: context.login,
    logout: context.logout
  };
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // Check for authentication state in local storage on component mount
  useEffect(() => {
    const storedAuthState = localStorage.getItem('authState');
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState);
      dispatch({ type: 'LOGIN', payload: parsedAuthState.user });
    }
  }, []);

  const login = (user: any) => {
    dispatch({ type: 'LOGIN', payload: user });
    // Store the authentication state in local storage
    localStorage.setItem('authState', JSON.stringify({ user }));
  };

  const logout = () => {
    // Clear the authentication state from local storage
    localStorage.removeItem('authState');
    dispatch({ type: 'LOGOUT' });
  };

  return <AuthContext.Provider value={{ state, login, logout }}>{children}</AuthContext.Provider>;
};