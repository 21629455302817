import React from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import theme from "./flowbite-theme"
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import SignInPage from "./pages/authentication/sign-in";
import { AuthProvider, useAuth } from "./AuthContext";
import CrewRoster from "./pages/crew/crew-roster";
import { Flowbite } from "flowbite-react";
import { SidebarProvider } from "./SidebarContext";
import CrewProfile from "./pages/crew/crew-profile";
import ForgotPassword from "./pages/authentication/forgot-password";
import ResetPassword from "./pages/authentication/reset-password";

const RootComponent: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Flowbite theme={{ theme }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignInPage />} index />
          <Route path="/myroster" element={isAuthenticated ? <CrewRoster /> : <SignInPage />} />
          <Route path="/myprofile" element={isAuthenticated ? <CrewProfile /> : <SignInPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </Flowbite>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <SidebarProvider>
        <RootComponent />
      </SidebarProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
