import { Badge, Sidebar, SidebarItem, SidebarItemGroup, SidebarItems, TextInput } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import {

    HiCalendar,
    HiSearch,
    HiUser,

} from "react-icons/hi"; 
import './Sidebar.css'
import { useSidebarContext } from "../SidebarContext";
const ExampleSidebar: FC = () => {
    const [currentPage, setCurrentPage] = useState("");
    useEffect(() => {
        const newPage = window.location.pathname;

        setCurrentPage(newPage);
    }, [setCurrentPage]);
    const { isOpen } = useSidebarContext();
    return (
        <Sidebar aria-label="Sidebar with multi-level dropdown example"  className="sidebar-main-conainer" collapsed={!isOpen}>
            <div className=" flex h-full flex-col justify-between py-2">
                <div>
                    <SidebarItems>
                        <SidebarItemGroup>
                            <SidebarItem href="/myroster"
                                icon={HiCalendar}
                                className={
                                    "/myroster" === currentPage
                                        ? "bg-gray-100 dark:bg-gray-700"
                                        : ""
                                }>My Roster</SidebarItem>
                                <SidebarItem href="/myprofile"
                                icon={HiUser}
                                className={
                                    "/myprofile" === currentPage
                                        ? "bg-gray-100 dark:bg-gray-700"
                                        : ""
                                }>My Profile</SidebarItem>
                        </SidebarItemGroup>
                    </SidebarItems>
                    <Sidebar.CTA>
                        <div className="mb-3 flex items-center">
                            <Badge color="warning">Crew Portal Beta 0.1</Badge>
                            <button
                                aria-label="Close"
                                className="-m-1.5 ml-auto inline-flex h-6 w-6 rounded-lg bg-gray-100 p-1 text-cyan-900 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600"
                                type="button"
                            >
                                <svg
                                    aria-hidden
                                    className="h-4 w-4"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="mb-3 text-sm text-cyan-900 dark:text-gray-400">
                        The Crew Portal application currently only allows viewing of assigned roster details.
                        </div>
                        
                    </Sidebar.CTA>
                </div>
            </div>


        </Sidebar>
    )
}

export default ExampleSidebar;