import { Alert, Button, Card, Checkbox, Label, Modal, TextInput, Toast } from "flowbite-react";
import { useEffect, useState, type FC } from "react";
import logo from '../../images/logo.png'
import signinImage from '../../images/signinImage.jpg'
import './sign-in.css'
import { API_ENDPOINTS } from '../../api'
import { useAuth } from '../../AuthContext'
import axios from "axios";
import { HiCheckCircle, HiExclamationCircle, HiFire, HiInformationCircle, HiMail, HiShieldCheck } from "react-icons/hi";
import { useLocation } from "react-router";

const ResetPassword: React.FC = () => {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isFialedEmail, setIsFialedEmail] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [openModalTerms, setOpenModalTerms] = useState(false);
    const [failedConfirmPassword, setFailedConformPassword] = useState(false);
    const [checkTerms, setCheckTerms] = useState(false);
    const { login } = useAuth()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        const tokenParam = queryParams.get('token');

        if (emailParam) {
            setEmail(emailParam);
        }

        if (tokenParam) {
            setToken(tokenParam);
        }
    }, [location.search]);

    interface ApiResponse {
        // Define the structure of the response data
        // You can adjust this interface based on the actual response structure
    }

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleCheckboxInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.checked)
        setCheckTerms(e.target.checked)
        if(password != confirmPassword){
            setFailedConformPassword(true)
        }else{
            setFailedConformPassword(false)
        }
    }

    const handleConfirmPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    }

    const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true)
        if(password != confirmPassword){
            setFailedConformPassword(true)
        }else{
            setFailedConformPassword(false)
            if(checkTerms){
                try {
                    const response = await axios.post<ApiResponse>(API_ENDPOINTS.UPDATE_CREW_PASSWORD_RESET, {
                        email: email,
                        password: password,
                    });
                    console.log('Forgot password successful:', response.data);
                    setIsSuccessful(true)
                } catch (error: any) {
                    console.error('Forgot password failed:', error.response.data);
                     
                  
                }
            }
            
        }
        
        setIsLoading(false)
    }
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await axios.post<ApiResponse>(API_ENDPOINTS.LOGIN, {
                email,
                password
            });
            console.log('Login successful:', response.data);
            login(response.data)
            window.location.href = '/myroster'
            // Redirect user or perform other actions upon successful login
        } catch (error: any) {
            console.log(error.response.status)
            if (error.response.status == 401) {
                setToastMessage("Incorrect email or password. Please try again.")
            } else if (error.response.status == 500) {
                setToastMessage("Something went wrong. Please try again later.")
            } else {
                setToastMessage("Something went wrong. Please try again later.")
            }
            setIsLoading(false)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 5300)
            //console.error('Login failed:', error.response.data);
            // Handle login failure (display error message, etc.)
        }
    };

    return (
        <div className="bg-topography flex min-h-screen flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">

            <Card className="w-full rounded-lg bg-white p-1 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-xl sm:p-2 md:mt-0" >
                {isSuccessful ? (
                    <div color="green" className="w-full rounded-lg bg-white p-6 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-xl sm:p-8 md:mt-0 ">
                        <h1 className="text-center mb-1 text-xl font-bold leading-tight tracking-tight text-green-500 dark:text-white md:text-2xl  " >
                            <HiShieldCheck className="mb-1 mr-1 inline-block text-xl font-bold leading-tight tracking-tight text-green-500 dark:text-white md:text-2xl" /> Password Reset Successfully
                        </h1>
                        <p className="mt-4 mb-3 text-center font-light text-gray-500 dark:text-gray-400"> Your password has been updated. You can now log in with your new password.
                        </p>
                    <div>
                        <Button className="w-full" onClick={() => window.location.href = '/'} color={'green'}> Go to Login</Button>
                    </div>
                    </div>

                ) : (<div className="w-full rounded-lg bg-white p-6 shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-xl sm:p-8 md:mt-0">
                    
                    {/* <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" /> */}
                    <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
                        Change Password
                    </h1>
                    <form className="mt-4 space-y-4 md:space-y-5 lg:mt-5" onSubmit={handleResetPassword}>
                        <div>
                            <Label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Your email</Label>
                            <TextInput value={email} type="email" name="email" id="email" className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500" placeholder="name@company.com" readOnly />
                        </div>
                        <div>
                            <Label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">New Password</Label>
                            <TextInput onChange={handlePasswordInput} color={failedConfirmPassword ? "failure" : "gray"}  type="password" name="password" id="password" placeholder="••••••••" className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500" required />
                        </div>
                        <div>
                            <Label htmlFor="confirm-password" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Confirm password</Label>
                            <TextInput onChange={handleConfirmPasswordInput} color={failedConfirmPassword ? "failure" : "gray"} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500" required />
                        </div>
                        <div className="flex items-start">
                            <div className="flex h-5 items-center">
                                <TextInput onChange={handleCheckboxInput} id="newsletter" aria-describedby="newsletter" type="checkbox" className="focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 size-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800" required />
                            </div>
                            <div className="ml-3 text-sm">
                                <Label htmlFor="newsletter" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="text-primary-600 dark:text-primary-500 font-medium hover:underline" onClick={() => setOpenModalTerms(true)}>Terms and Conditions</a></Label>
                            </div>
                        </div>
                        <Button type="submit" className="hover:bg-primary-700 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4">Reset password</Button>
                    </form>
                    {/* <div className="flex justify-center gap-4 align-middle">
                        <img src={logo} className="mb-4 h-10 w-auto" alt="Logo" />
                        <h4 className="font-bold leading-tight tracking-tight text-blue-900">Crew Portal</h4>
                    </div> */}
                </div>)
                }

            </Card >
            {showToast && <Toast className="toast-postion">
                <div className="inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                    <HiExclamationCircle className="size-5" />
                </div>
                <div className="ml-3 text-sm font-normal">{toastMessage}</div>
                <Toast.Toggle onDismiss={() => setShowToast(false)} />
            </Toast>}
            <Modal dismissible show={openModalTerms} onClose={() => setOpenModalTerms(false)}>
                <Modal.Header>Terms and Conditions</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            {/* <HiCheckCircle/> */}
                            By requesting a password reset, you confirm that you are the authorized user of the account associated with the provided email address.
                            You are responsible for keeping the reset link confidential and should not share it with anyone else.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            The password reset link is valid for a limited time and will expire after 60 minutes. If the link expires, you will need to request a new one.
                            For your security, we recommend creating a strong and unique password.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            If you did not request a password reset, please ignore this email and contact our support team immediately if you suspect any unauthorized activity.
                            Your account may be temporarily locked after multiple unsuccessful reset attempts to protect against unauthorized access.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            We take your privacy seriously. The information provided during the password reset process will only be used to verify your identity and reset your password.
                            If you encounter any issues, please contact our support team for assistance.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color={'blue'} onClick={() => setOpenModalTerms(false)}>I accept</Button>
                    <Button color="gray" onClick={() => setOpenModalTerms(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default ResetPassword;